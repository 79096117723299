import React from "react";
//context
import Context from 'context/';

export const wrapRootElement = ({ element }) => {
  return (
    <Context>{element}</Context>
  );
};

//for safari
// export const onClientEntry = async () => {
//   if (typeof IntersectionObserver === "undefined") {
//     await import("intersection-observer")
//   }
// }